<template>
  <form-wizard
    ref="refFormWizard"
    color="#7367F0"
    :title="null"
    :subtitle="null"
    finish-button-text="Submit"
    back-button-text="Previous"
    hide-buttons
    class="checkout-form-wizard steps-transparent"
  >

    <!-- 訂單完成 -->
    <tab-content
      title="訂單完成"
      icon="feather icon-check-circle"
    >
      <div
        v-if="orderDetails"
        class="list-view product-checkout"
      >
        <div class="amount-payable checkout-options">
          <b-card>
            <b-card-title>
              <div class="h2 font-weight-bolder">
                我們已經收到您的訂單
              </div>
            </b-card-title>

            <ul class="pl-2">
              <li class="mb-75">
                訂單編號：{{ orderDetails.serial_no }}
              </li>
              <li class="mb-75">
                預約時間：{{ (new Date(orderDetails.starts_at)).format('yyyy-MM-dd') }}
              </li>
              <li class="mb-75">
                預約時間：{{ (new Date(orderDetails.starts_at)).format('hh:mm') }} ~ {{ (new Date(orderDetails.ends_at)).format('hh:mm') }}
              </li>
              <li class="mb-75">
                預約項目：<span class="font-weight-bolder">{{ orderDetails.items.map(m => m.name).join(', ') }}</span>
              </li>
              <li class="mb-75">
                付款狀態：{{ orderDetails.status }}
              </li>
            </ul>
          </b-card>
        </div>
      </div>
    </tab-content>
  </form-wizard>
</template>

<script>
import {
  BCard, BCardTitle,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import { FormWizard, TabContent } from 'vue-form-wizard'
import store from '@/store'

import dataStoreModule from './reserveStoreModule'

export default {
  components: {
    BCard,
    BCardTitle,

    FormWizard,
    TabContent,
  },
  setup(_, { root }) {
    const APP_STORE_MODULE_NAME = 'app-reserve-order'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, dataStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const orderDetails = ref(null)
    store.dispatch(`${APP_STORE_MODULE_NAME}/fetchReserveOrder`, {
      id: root.$route.params.serial_no,
      slug: root.$route.params.slug,
    })
      .then(response => {
        orderDetails.value = response.data
      })

    // const cartItemsSum = items => items.reduce((total, item) => total + (item.price * item.qty), 0)

    return {
      orderDetails,
      // cartItemsSum,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import "~vue-form-wizard/dist/vue-form-wizard.min.css";
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
